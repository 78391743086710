import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="min-h-screen bg-gray-900 flex flex-col items-center justify-center">
          <h1 class="text-5xl text-white font-bold mb-8 animate-pulse">
            Coming Soon
          </h1>
          <p class="text-white text-lg mb-8">
            We're working hard to bring you something amazing. Stay tuned!!!
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
